<template>
  <div>
    <div class="section-title section-title_app">
      <h3>CITA MEDICA</h3>
    </div>
    <div class="columns is-multiline is-gapless">
      <div class="column is-12 has-background-white">
        <div style="display: flex; flex-wrap: wrap; gap: 16px; padding: 16px;">
          <b-tooltip label="Nueva Cita"><b-button icon-left="file" size="is-large" type="is-primary"></b-button></b-tooltip>
          <b-tooltip label="Guardar Cita"><b-button icon-left="save" size="is-large" type="is-primary"></b-button></b-tooltip>
          <b-tooltip label="Enviar Cita"><b-button icon-left="envelope" size="is-large" type="is-primary"></b-button></b-tooltip>
          <b-tooltip label="Cita Anterior"><b-button icon-left="arrow-left" size="is-large" type="is-primary"></b-button></b-tooltip>
          <b-tooltip label="Cita Siguiente"><b-button icon-left="arrow-right" size="is-large" type="is-primary"></b-button></b-tooltip>
          <div class="column is-1">
            <b-field label="# Cita">
              <b-input>
              </b-input>
            </b-field>
          </div>
          <div class="column is-2">
            <b-field label="Fecha de cita"
                     label-position="on-border">
              <b-datepicker placeholder="2023"
                            v-model="date"
                            :max-date="maxDatetime"
                            nearby-selectable-month-days
                            :min-date="minDatetime">

              </b-datepicker>
            </b-field>
          </div>
          <div class="column is-4">
            <b-field label="Hora de la cita">
              <b-timepicker
                  placeholder="Click to select"
                  icon="clock"
              />
            </b-field>
          </div>
        </div>

        <div class="column is-12 columns is-multiline">
          <div class="column is-12 is-7-desktop is-12-widescreen is-9-fullhd has-background-white is-multiline">
            <div class="is-divider" data-content="PACIENTE"></div>
            <div class="columns is-multiline">
              <div class="column is-12">
                <select-patient class="columns" type="search"

                ></select-patient>
              </div>
            </div>

          </div>
        </div>
        <div class="column is-12">
          <b-tabs size="is-small">
            <b-tab-item label="Diagnósticos" value="diagnosticos">
              <OrderDiagnostics :data="{}"/>
            </b-tab-item>
          </b-tabs>
        </div>

        <div class="column is-12">
          <b-field grouped group-multiline>
            <p class="control">
              <button class="button field is-danger"
              >
                <b-icon icon="eraser"></b-icon>
              </button>
            </p>
            <p class="control">
              <button  class="button">
                <b-icon icon="trash"></b-icon>
              </button>
            </p>
            <p class="control">
              <select-study
                  :can-block="false"
                  api-url="price-list"
                  type="quotation"
                  :company="{}"
              ></select-study>
            </p>
          </b-field>

        </div>

        <div class="column ">
          <b-table
              :checkable="true"
              :data="[]"
              striped
              bordered
              narrowed
              detailed
              sticky-header
              custom-detail-row
          >
            <b-table-column field="name" label="Estudio" v-slot="props">
              {{ props.row.name }}
            </b-table-column>
            <b-table-column field="name" label="Indicaciones" v-slot="props">
              {{ props.row.name }}
            </b-table-column>
            <template slot="detail" slot-scope="props">
              <tr v-for="item in props.row.items" :key="`${props.row.code}-${item.code}`">
                <td></td>
                <td></td>
                <td>
                  <b-collapse :open="false" aria-id="contentIdForA11y1" v-if="item.has_sub_studies">
                    <a @click="onOpenDetail(item)" slot="trigger">{{ item.code }}</a>
                    <table v-if="!!item.items">
                      <tr v-for="sub in item.items">
                        <td>
                          <b-collapse :open="false" aria-id="contentForSubStudies" v-if="sub.has_sub_studies">
                            <a @click="onOpenDetail(sub)" slot="trigger">{{ sub.code }}</a>
                            <table v-if="!!item.items">
                              <tr v-for="subSub in sub.items">
                                <td>
                                  {{ subSub.code }}
                                  {{ subSub.name }}
                                </td>
                              </tr>
                            </table>
                          </b-collapse>
                          <span>
                                {{ sub.code }}
                              </span>
                        </td>
                        <td>{{ sub.name }}</td>
                      </tr>
                    </table>
                  </b-collapse>
                  <span v-else>
                        {{ item.code }}
                      </span>
                </td>
                <td>{{ item.name }}</td>
              </tr>
            </template>
            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <p>
                    <b-icon
                        icon="box-open"
                        size="is-large">
                    </b-icon>
                  </p>
                  <p>No hay estudios seleccionados.</p>
                </div>
              </section>
            </template>
          </b-table>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import SelectPatient from "@/components/patient/SelectPatient";
import OrderDiagnostics from "../../components/order/OrderDiagnostics.vue";
import SelectStudy from "../../components/study/SelectStudy.vue";
export default {
  name: "Index",
  components: {
    SelectStudy,
    OrderDiagnostics,
    SelectPatient,
  },
  data() {
    const maxYear = new Date()
    maxYear.setFullYear(maxYear.getFullYear() + 2, 0, 0)
    const minYear = new Date()
    minYear.setFullYear(minYear.getFullYear() - 1, 12, 1)
    return {
      date: new Date(),
      maxDatetime: maxYear,
      minDatetime: minYear,
    }
  },
  mounted() {
    this.wrapper = document.getElementById('main-wrapper');
    this.wrapper.classList.add('is-expanded');
  },
}
</script>

<style scoped>

</style>